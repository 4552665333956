import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import EditMainMeaning from "./EditMainMeaning";
import EditYourMeaning from './EditYourMeaning';
import configData from "../../../config.json";
import { Button, Card, Row, Table, Spinner } from 'react-bootstrap';


const AImeaningWord = ({ currentword, token }) => {
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [meanings, setMeanings] = useState({});
    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };
    const fetchAiWordMeaning = async () => {
        try {

            const response = await axios.post(`${configData.SERVER_URL}/api/AI/GetAIMeanings/${currentword.boxId}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data.data;
            setMeanings(data)
            console.log(data);
            setLoading(false)
        } catch (error) {
            console.error('Error FindsimiliarWords:', error);

        }
    };

    useEffect(() => {

        setLoading(true)
        fetchAiWordMeaning();
    },
        [currentword])

    return (
        <Row>
            <Card>
                <Card.Header>

                    {!isOpen && <Button rounded="true" className='mx-2 m-2 w-100' variant='success' onClick={toggleCollapse}>مشاهده معنی هوش مصنوعی  <i className="fa-solid fa-expand"></i> </Button>}
                    {isOpen && <Button rounded="true" className='mx-2 m-2 w-100' variant='danger' onClick={toggleCollapse}>مخفی کردن محتوا    <i className="fa-solid fa-compress"></i> </Button>}

                </Card.Header>
                {isOpen && (
                    <div id="Meanings">
                        {loading && <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                        <Card.Body>{!loading &&
                            <Table responsive striped bordered hover>
                                <thead className='bg-green-500 text-center'><th>معنی فارسی</th><th>معنی انگلیسی</th><th>مثال</th></thead>
                                <tbody>
                                    {
                                        meanings.map((meaning) => (
                                            <tr key={meaning.id}>
                                                <td>{meaning.persian}</td><td>{meaning.meaning} </td><td>{meaning.example}</td>
                                            </tr>
                                        ))
                                    }</tbody></Table>}
                        </Card.Body>
                        <Card.Footer>

                        </Card.Footer>

                    </div>
                )}
            </Card>



        </Row>
    );
};

export default AImeaningWord;
